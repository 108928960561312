<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Auth',
  computed: {
    ...mapGetters('roles', ['isWebmaster', 'isAdvertiser', 'isAdministrator']),
  },
  watch: {
    isWebmaster() {
      this.redirect('sites');
    },
    isAdvertiser() {
      this.redirect('campaigns');
    },
    isAdministrator() {
      this.redirect('admin-advertisers');
    },
  },
  methods: {
    redirect(routeName) {
      this.$router.push({ name: routeName });
    },
  },
};
</script>
